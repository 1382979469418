import React from "react";

export default function Breadcrumb({title}) {
  return (
                        <div className="row breadcrumb-div" >
                        <div className="col-md-12 " >
                        <div className="p-3 m-3 text-white  breadcrumb-border" >
                                <h1 className="text-center"> {title}</h1>
                                <nav aria-label="breadcrumb ">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item breadcrumb-home"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active text-white" aria-current="page">{title}</li>
                                </ol>
                                </nav>
                            </div>
                            </div>
                            </div>
  );
}
