import React ,{Component } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';

class AboutMe extends Component{
    constructor(props)
    {
      super(props);
    }
    render()
	{
		return (
			<>
            <Navbar transparent />
            <Breadcrumb title="About Me"/>
            <div className="container p-5">
				<div className="row">
					<div className="col-md-6">
						<img className="img-responsive w-100" src="../assets/about.png" alt="About Us"/>
					</div>
					<div className="col-md-6 mt-5">
                        <h2 className='mb-3 text-center'>About Me</h2>
                        <div className='headingBorder mb-5'></div>
						<p className='mt-3'>Hi! My Name is <strong>Sushma Yadav</strong>, Software Developer by profression. As of Jan 2020, I started to write my own thoughts, literally anything that comes to my mind. Thats all I have pen, paper and thoughts. Writing my thoughts to paper, its like writing my heart on those paper. Thats when I decided to give it a name, paper and thoughts.</p>
						<blockquote className='mt-5'>
							 "As a person, I strongly believe that we must have the desire to achieve our goal and there should a balance between life and work."  
						</blockquote>
						
					</div>
				</div>
			</div>
            <Footer />
            </>
        )
    }

}

export default AboutMe;