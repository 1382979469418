import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom'
import Home from './views/Home';
import About from './views/pages/About';
import Contact from './views/pages/Contact';
import TermsAndConditions from './views/pages/TermsAndConditions';
import Policy from './views/pages/Policy';
import Sitemap from './views/pages/Sitemap';
import Quote from './views/quote/Quote';
import Blog from './views/blog/Blog';
import BlogView from './views/blog/BlogView';
import BlogAdd from './views/adminBlog/BlogAdd';
import QuoteAdd from './views/adminBlog/QuoteAdd';
import Login from './admin/Login';
import 'react-multi-carousel/lib/styles.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-quill/dist/quill.snow.css';
import {Navigate} from 'react-router-dom';
import { Helmet } from "react-helmet";

const PrivateRoute = (props) => {
  return localStorage.getItem('userId') ? <props.component /> : <Navigate to="/login"/>
}

function App() {
  return (
    <div className="App">
       <Helmet>
       <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8749629583391710"
     crossorigin="anonymous"></script>
      </Helmet>

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<Policy />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/quotes" element={<Quote />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogView />} />
            <Route exact path="/blog-add" element={<PrivateRoute component={BlogAdd}/>}/>
            <Route exact path="/quote-add" element={<PrivateRoute component={QuoteAdd}/>}/>
            <Route path="/login" element={<Login />} />
        </Routes>
    </div>
  );
}

export default App;
