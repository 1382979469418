import React from "react";

export default function ComingSoon({title}) {
  return (
    <div className="w-full ">
    <div className="text-center">
      <h1 className=""><i>Coming <span className="text-pnt ">soon</span> ......</i></h1>
     <img
       alt="coming-soon"
       src="../assets/coming-soon2.png"
       className="img-responsive w-100"
     />
   </div>
 </div>            
  );
}
