import React ,{Component } from 'react';
import Card from "../components/card";
import { Constant } from "../helpers/Constants";
import axios from 'axios';
import Toaster from "../helpers/Toaster";
import Carousel from 'react-multi-carousel';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
class HomeBlog extends Component{
  constructor(props)
  {
    super(props);
    this.state = {
      blogLists: []
  }
  this.blogList = this.blogList.bind(this);
  }
  componentDidMount() {
    this.blogList();
}
  blogList(){
		axios.get(Constant.server_url  + Constant.urlsuffix + `/blog-list/home`)
      .then((res) => {
        this.setState({ blogLists: res.data.data });
      })
      .catch((err) => {
        new Toaster({ type: "error", msg: "Something Went Wrong." });
      });
	}
  render()
{
  return (
    <>
      <div className="homeBlogSection p-3">
      <div className="container p-3">
      <div className="container">
				<div className="row row-pb-md">
          <div className='col-md-6 mt-5'>
                <h2 className='mb-3'>Blogs</h2>
                        <div className='headingBorder ml-0 mb-5 w-40'></div>
              </div>
              <div className='col-md-6 mt-5 ml-auto'>
              <a href="/blogs" className="btn btn-primary float-right"> View All </a>
              </div>
                <Carousel responsive={responsive}>
                  {
                    this.state.blogLists.map((value, index) => (
                      <div key={index}>
                        <a href={"blog/" + value.blog_slug} className="bloglink">
                        <Card {...value}/>
                        </a>
                        </div>
                    ))
                  }
</Carousel>
				</div>

			</div>
      </div>
      </div>
    </>
  );
}
}

export default HomeBlog;
