import React, { Component } from "react";
import Breadcrumb from "../../components/breadcrumb";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import Card2 from "../../components/card2";
import Card from "../../components/card";
import axios from 'axios';
import Toaster from "../../helpers/Toaster";
import { Constant } from "../../helpers/Constants";

class BlogView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogDetail: {},
      blogLists: []
    };
    this.blogDetail = this.blogDetail.bind(this);
    this.blogList = this.blogList.bind(this);
  }
  componentDidMount() {
    this.blogDetail();
    this.blogList();
  }
  blogList(){
		axios.get(Constant.server_url  + Constant.urlsuffix +  '/blog-list/home')
      .then((res) => {
        this.setState({ blogLists: res.data.data });
      })
      .catch((err) => {
        new Toaster({ type: "error", msg: "Something Went Wrong." });
      });
	}
  blogDetail() {
    //   console.log(window.location.pathname);
    var module = window.location.pathname;
    var blog = module.split("/").at(-1);
    axios
      .get(Constant.server_url + Constant.urlsuffix  + `/blog-detail/` + blog )
      .then((res) => {
        this.setState({ blogDetail: res.data.data[0] });
      })
      .catch((err) => {
        new Toaster({ type: "error", msg: "Something Went Wrong." });
      });
  }
  render() {
    return (
      <>
        <Navbar transparent />
        <Breadcrumb title="Blogs" />
        <div className="container p-5">
          <div className="row">
            <div className="col-md-4">
            <h3 className=""> Recent Post</h3>
              <div className="headingBorder ml-0 mb-5 w-50"></div>
              {
                    this.state.blogLists.map((value, index) => (
                      <div key={index} className="">
                        <a href={"/blog/" + value.blog_slug} className="bloglink">
                        <Card2 {...value}/>
                        </a>
                        </div>
                    ))
                  }
            </div>
            <div className="col-md-8">
              <h1 className="text-center"> {this.state.blogDetail.blog_title}</h1>
              <div className="headingBorder mb-5"></div>
              <div className="row">
              <div className="col-md-12">
                {
                  (this.state.blogDetail.blog_image != '') ?
                    <img className="img-responsive w-100 mb-5" src={Constant.server_url + Constant.urlsuffix + '/' + this.state.blogDetail.blog_image} alt="Blog"/>                 
                  : ''
                }
              { <div dangerouslySetInnerHTML={{ __html: this.state.blogDetail.blog_content}} /> }
              </div>
              </div>
              
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default BlogView;
