import React, { Component } from "react";
import Breadcrumb from "../components/breadcrumb";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import { Constant } from "../helpers/Constants";
import axios from "axios";
import Toaster from "../helpers/Toaster";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
  let navigate = useNavigate();
  return <LoginClass {...props} navigate={navigate}/>
}

class LoginClass extends Component { 
  
  constructor(props) {
    super(props);
    this.state = {
      form: {
        username: "",
        password: "",
      },
      formErrors: {},
    };
    this.checkLogin = this.checkLogin.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
    localStorage.removeItem("userId");
  }
  inputHandler(event, type) {
    let inputValue = event.target.value;
    this.setState({
      form: { ...this.state.form, [type]: inputValue },
    });
    if (event.target.value !== "") {
      let keyName = event.target.name;
      this.setState({
        formErrors: { ...this.state.formErrors, [keyName]: "" },
      });
    }
  }
  checkLogin(e) {
    e.preventDefault();
    const fd = this.state.form;
    if (this.state.form.username === "" || this.state.form.username === "") {
      new Toaster({ type: "error", msg: "Please fill all fields" });
      return false;
    }
    axios
      .post(`${Constant.server_url}:${Constant.port+ Constant.urlsuffix}/login`, fd)
      .then((res) => {
        localStorage.setItem("userId", res.data.data[0].user_id);
        this.props.navigate("/blog-add");
        // new Toaster({ type: "success", msg: res.data.message });
        
      })
      .catch((err) => {
        this.setState({
          formErrors: err.response.data,
        });
        new Toaster({ type: "error", msg: "Something Went Wrong." });
      });
  }
  handleCallback = (childData) => {
    // Update the name in the component's state
    this.setState({
      form: { ...this.state.form, message: childData },
    });
  };
  render() {
    return (
      <>
        <Navbar transparent />
        <Breadcrumb title="Login" />
        <div className="container p-5">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <h2 className="heading-2 text-center">Login</h2>
                  <div className="headingBorder mb-5"></div>

                  <form
                    method="post"
                    onSubmit={this.checkLogin}
                  >
                    <div className="row form-group mb-3">
                      <div className="col-md-12">
                        <label htmlFor="fname">Username</label>
                        <input
                          type="text"
                          name="username"
                          id="username"
                          className="form-control"
                          placeholder="Your Username"
                          onChange={(event) => {
                            this.inputHandler(event, "username");
                          }}
                          required
                        />
                        <span className="text-danger">
                          {this.state.formErrors.hasOwnProperty("username")
                            ? this.state.formErrors.username.msg
                            : ""}
                        </span>
                      </div>
                    </div>

                    <div className="row form-group mb-3">
                      <div className="col-md-12">
                        <label htmlFor="fname">Password</label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className="form-control"
                          placeholder="Your Password"
                          onChange={(event) => {
                            this.inputHandler(event, "password");
                          }}
                          required
                        />
                        <span className="text-danger">
                          {this.state.formErrors.hasOwnProperty("password")
                            ? this.state.formErrors.password.msg
                            : ""}
                        </span>
                      </div>
                    </div>

                    <div className="form-group text-center">
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-primary"
                      />
                    </div>
                  </form>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Login;
