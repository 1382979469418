import React from "react";

export default function Footer() {
	const currentYear = new Date().getFullYear();
  return (
    <>
      <footer id="colorlib-footer" role="contentinfo">
			<div className="container footerBorder">
				<div className="row row-pb-md">
					<div className="col-md-4">
						<h2>Navigational</h2>
						<div className="headingBorder ml-0 mb-5 w-60"></div>
						<p>
							</p><ul className="colorlib-footer-links">
								<li><a href="/"><i className="fa fa-check"></i> Home</a></li>
								<li><a href="/about"><i className="fa fa-check"></i> About Me</a></li>
								<li><a href="/contact"><i className="fa fa-check"></i> Contact</a></li>
								<li><a href="/terms-and-conditions"><i className="fa fa-check"></i> Terms and Condition</a></li>
								<li><a href="/privacy-policy"><i className="fa fa-check"></i> Privacy Policy</a></li>
								<li><a href="/sitemap"><i className="fa fa-check"></i> Sitemap</a></li>
							</ul>
						<p></p>
					</div>
					<div className="col-md-4">
						<h2>Contact</h2>

						<div className="headingBorder ml-0 mb-5 w-60"></div>
												{/* <div className="f-blog">
							<a href="http://localhost:8080/pnt/blog/test" className="blog-img" >
							</a>
							<div className="desc">
								<h3><a href="http://localhost:8080/pnt/blog/test">test</a></h3>
								<p className="admin"><span>11 Oct, 2023</span></p>
							</div>
						</div> */}
						<div className="row footerContact row-pb-lg ">
							<div className="col-md-12">
								<p className="text-white"><span><i className="fa fa-map"></i></span> Gurugram, India </p>
							</div>
							<div className="col-md-12">
								<p ><span><i className="fa fa-envelope"></i></span> <a className="text-white" href="mailto:paperandthoughtsblog@gmail.com">paperandthoughtsblog@gmail.com</a></p>
							</div>
							<div className="col-md-12">
								<p><span><i className="fa fa-globe"></i></span> <a href="#" className="text-white">paperandthoughts.com</a></p>
							</div>
							<div className="col-md-12">
								<p className="d-inline footerContactIcon"><a href="https://facebook.com/profile.php?id=100008393323174"><i className="fa fa-facebook-square"></i></a></p>
								<p className="d-inline footerContactIcon"><a href="https://www.instagram.com/sushma_singh_yadav/"><i className="fa fa-instagram"></i></a></p>
								<p className="d-inline footerContactIcon"><a href="https://www.linkedin.com/in/sushma-singh-yadav/"><i className="fa fa-linkedin-square"></i></a></p>
							</div>
						</div>
											</div>
					<div className="col-md-4">
						<h2>Tags</h2>

						<div className="headingBorder ml-0 mb-5 w-60"></div>
						<p className="tags">
							<span><a href="#"><i className="fa fa-tag"></i> Thoughts</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Paper</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Life</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Blog</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Vacation</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Travel</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Perception</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Women</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Nature</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Art</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Sea</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Ocean</a></span>
							<span><a href="#"><i className="fa fa-tag"></i> Home</a></span>
						</p>
					</div>
				</div>
				</div>
				<div className="row row-pb-md">
				<div className="col-md-12">
						<p className="text-center text-white mt-5 p-0"> &copy; 2022 - {currentYear} Paper and Thoughts | All Right Reserved</p>
				</div>
				</div>
		</footer>
    </>
  );
}
