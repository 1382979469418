import React ,{Component } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import { Constant } from "../../helpers/Constants";
import axios from 'axios';
import Toaster from "../../helpers/Toaster";
import Editor from '../../components/editor';

class BlogAdd extends Component{
    constructor(props)
    {
      super(props);
	  this.state = {
		  form: {
		  title:'',
		  slug: '',
		  blogImage: '',
            message:'',
		  },
		  formErrors: {},
	  };
	  this.saveContact = this.saveContact.bind(this);
	  this.inputHandler = this.inputHandler.bind(this);
    }
	inputHandler(event,type){
		var inputValue = '';
		if(type === 'blogImage')
		{
			inputValue = event.target.files[0];
		} else {
			inputValue = event.target.value;
		}
        this.setState({
			form: { ...this.state.form, [type] : inputValue}
        });
		if(event.target.value != '')
		{
			let keyName = event.target.name;
			this.setState({
				formErrors : { ...this.state.formErrors, [keyName]: ''}
			 });
		}
    }
	saveContact(e){
		e.preventDefault();
        const fd = this.state.form;
		if(this.state.form.title === '' || this.state.form.blogImage === '' || this.state.form.message === '' || this.state.form.slug === '')
		{
			 new Toaster({ type: "error", msg: 'Please fill all fields' });
			 return false;
		}
		axios.post(Constant.server_url  + Constant.urlsuffix +  '/' + `blog-add`, fd,{
			headers: {
				'Content-Type': 'multipart/form-data'
			  }
		})
      .then((res) => {
		  console.log(res);
		new Toaster({ type: "success", msg: res.data.message });
      })
      .catch((err) => {
		this.setState({
			formErrors : err.response.data
		});
        // new Toaster({ type: "error", msg: "Something Went Wrong." });
      });
	}
	handleCallback = (childData) => {
        // Update the name in the component's state
        this.setState({ 
			form: { ...this.state.form, 'message': childData}
		 });
    };
    render()
	{
		return (
			<>
            <Navbar transparent />
            <Breadcrumb title="Blog Add"/>
            <div className="container p-5">
				<div className="row">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-12">
								<h2 className="heading-2 text-center">Blog Add</h2>
                                <div className='headingBorder mb-5'></div>
								
								<form method="post" onSubmit={this.saveContact} encType="multipart/form-data">
									<div className="row form-group mb-3">
										<div className="col-md-12">
											<label htmlFor="fname">Title</label>
											<input type="text" name="title" id="fname" className="form-control" placeholder="Your title" onChange={(event)=>{this.inputHandler(event,"title")}} required/>
											<span className='text-danger'>{(this.state.formErrors.hasOwnProperty("title")) ? this.state.formErrors.title.msg : ''}</span>
										</div>
									</div>

									<div className="row form-group mb-3">
										<div className="col-md-12">
											<label htmlFor="fname">Slug</label>
											<input type="text" name="slug" id="slug" className="form-control" placeholder="Your slug" onChange={(event)=>{this.inputHandler(event,"slug")}} required/>
											<span className='text-danger'>{(this.state.formErrors.hasOwnProperty("slug")) ? this.state.formErrors.slug.msg : ''}</span>
										</div>
									</div>


									<div className="row form-group  mb-3">
										<div className="col-md-12">
											<label htmlFor="email">Image</label>
											<input type="file" name="blogImage" id="image" className="form-control" onChange={(event)=>{this.inputHandler(event,"blogImage")}} required/>
											<span className='text-danger'>{(this.state.formErrors.hasOwnProperty("blogImage")) ? this.state.formErrors.blogImage.msg : ''}</span>
										</div>
									</div>

									<div className="row form-group  mb-3">
										<div className="col-md-12">
											<label htmlFor="message">Message</label>
											<Editor parentCallback={this.handleCallback}/>
											<span className='text-danger'>{(this.state.formErrors.hasOwnProperty("message")) ? this.state.formErrors.message.msg : ''}</span>
										</div>
									</div>


									<div className="form-group">
										<input type="submit" value="Add" className="btn btn-primary"/>
									</div>

								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
            <Footer />
            </>
        )
    }

}

export default BlogAdd;