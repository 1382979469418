import React, { useState } from "react";
import { Constant } from "../helpers/Constants";

export default function Slider() {
    const [isActive, setActiveSlide] = useState(0);
  return (
    <>
<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  {/* <ol className="carousel-indicators">
    <li  data-slide-to="0" className={isActive === 0 ? "active" : ""} onClick={() => setActiveSlide(0)}></li>
    <li  data-slide-to="1" className={isActive === 1 ? "active" : ""} onClick={() => setActiveSlide(1)}></li>
    <li  data-slide-to="2" className={isActive === 2 ? "active" : ""} onClick={() => setActiveSlide(2)}></li>
  </ol> */}
  <div className="carousel-inner">
    <div className={isActive === 0 ? "carousel-item active" : "carousel-item"} >
      <img className="d-block w-100" src={Constant.server_url + Constant.urlsuffix + '/' + "uploads/quote2.png"} alt="First slide"/>
    </div>
    <div className={isActive === 1 ? "carousel-item active" : "carousel-item"}>
      <img className="d-block w-100" src={Constant.server_url + Constant.urlsuffix + '/' + "uploads/quote1.jpg"} alt="Second slide"/>
    </div>
    <div className={isActive === 2 ? "carousel-item active" : "carousel-item"}>
      <img className="d-block w-100" src={Constant.server_url + Constant.urlsuffix + '/' + "uploads/quote3.jpg"} alt="Third slide"/>
    </div>
  </div>
  <a className="carousel-control-prev" onClick={() => setActiveSlide((isActive - 1 < 0) ? 2 : isActive - 1)}  role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" onClick={() => setActiveSlide((isActive + 1 > 2) ? 0 : isActive + 1)} role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
    </>
  );
}
