import React from "react";
import { Constant } from "../helpers/Constants";

export default function Card(cardDetails) {
  return (
   
                        <div className="card mr-3" >
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-3">
                                <img className="img-responsive w-100" src={Constant.server_url  + Constant.urlsuffix + '/' + cardDetails.blog_image} alt="Blog"/>
                                </div>
                                <div className="col-md-9">
                                <h5 className="">{cardDetails.blog_title}</h5>
                                </div>
                              </div>
                                
                            </div>
                            </div>
  );
}
