import React from "react";
// components
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import HomeBlog from "../views/Home-Blog.js";
import HomeQuote from "../views/Home-Quote.js";
import Slider from "./Slider.js";

export default function Home() {
  return (
    <>
      <Navbar transparent />
      <Slider />
      <HomeBlog />
      <HomeQuote />
      <Footer />
    </>
  );
}
