import React from "react";
import { Constant } from "../helpers/Constants";

export default function Card(cardDetails) {
  return (
   
                        <div className="card mr-3" >
                           <img className="card-img-top img-responsive w-100 cardImg" src={Constant.server_url  + Constant.urlsuffix + '/' + cardDetails.blog_image} alt="Blog"/>
                            {/* <img className="card-img-top" src="..." alt="Card image cap"/> */}
                            <div className="card-body">
                                <h5 className="card-title">{cardDetails.blog_title}</h5>
                                {/* <p className="card-text">{cardDetails.blog_content} sknfbd</p>
                                <a href="#" className="btn btn-primary">Go somewhere</a> */}
                            </div>
                            </div>
  );
}
