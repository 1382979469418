import React ,{Component } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import { Constant } from "../../helpers/Constants";
import axios from 'axios';
import Toaster from "../../helpers/Toaster";
import ComingSoon from '../../components/comingsoon';

class Quote extends Component{
    constructor(props)
    {
      super(props);
	  this.state = {
		  quotes:[]
	  };
	  this.quoteList = this.quoteList.bind(this);
    }
	componentDidMount() {
		this.quoteList();
	}
	quoteList(){
		axios
      .get(Constant.server_url  + Constant.urlsuffix +  `/quote-list/all`)
      .then((res) => {
		  console.log(res);
        this.setState({ quotes: res.data.data });
      })
      .catch((err) => {
        new Toaster({ type: "error", msg: "Something Went Wrong." });
      });
	}
    render()
	{
		return (
			<>
            <Navbar transparent />
            <Breadcrumb title="Quotes"/>
            <div className="container p-5">
				<div className="row">
				{   (this.state.quotes.length > 0 ) ? (
                    this.state.quotes.map((value,index) => (
					<div className="col-md-6 mb-3" key={index}>
			            {/* <div className="blog-entry">
			              <div className="blog-img"> */}
			                	<img src={Constant.server_url + Constant.urlsuffix + '/' + value.quotes_img} className="img-responsive blogimg d-inline" alt="Quotes" id="image"/>
			              {/* </div>
			            </div> */}
			          </div>
				 )))
					: <ComingSoon/>} 
				</div>
			</div>
            <Footer />
            </>
        )
    }

}

export default Quote;