import React ,{Component } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';

class Sitemap extends Component{
    constructor(props)
    {
      super(props);
    }
    render()
	{
		return (
			<>
            <Navbar transparent />
            <Breadcrumb title="Sitemap"/>
            <div className="container p-5">
				<div className="row">
					<div className="col-md-3">
						<img className="img-responsive w-100" src="../assets/about.png" alt="About Us"/>
					</div>
					<div className="col-md-9 mt-5">
                        <h2 className='mb-3 text-center'>Sitemap</h2>
                        <div className='headingBorder mb-5'></div>
                        <div className='row'>
                            <div className='col-md-6'>
                        <ul>
                            <li className='mb-2'><a href='/'>Home</a></li>
                            <li className='mb-2'><a href='/about'>About Me</a></li>
                            <li className='mb-2'><a href='/contact'>Contact</a></li>
                            <li className='mb-2'><a href='/terms-and-conditions'>Terms & Conditions</a></li>
                            <li className='mb-2'><a href='/policy'>Privacy Policy</a></li>
                            <li className='mb-2'><a href='/sitemap'>Sitemap</a></li>
                        </ul>

                            </div>
                            <div className='col-md-6'>
                            <ul>
                            <li className='mb-2'><a href='/blogs'>Blog</a></li>
                            <li className='mb-2'><a href='/quotes'>Quote</a></li>
                            <li className='mb-2'><a href='/sitemap'>Sitemap</a></li>
                        </ul>
                            </div>
                        </div>
                        
					</div>
				</div>
			</div>
            <Footer />
            </>
        )
    }

}

export default Sitemap;