import React ,{Component } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import { Constant } from "../../helpers/Constants";
import axios from 'axios';
import Toaster from "../../helpers/Toaster";

class Contact extends Component{
    constructor(props)
    {
      super(props);
	  this.state = {
		  form: {
		  firstName:'',
		  lastName:'',
            email:'',
			subject: '',
            message:'',
		  },
		  formErrors: {}
	  };
	  this.saveContact = this.saveContact.bind(this);
	  this.inputHandler = this.inputHandler.bind(this);
    }
	inputHandler(event,type){
        this.setState({
			form: { ...this.state.form, [type] : event.target.value}
        });
		if(event.target.value != '')
		{
			// console.log('sdkfjkdf' +  event.target.name)
			let keyName = event.target.name;
			this.setState({
				formErrors : { ...this.state.formErrors, [keyName]: ''}
			 });
		}
    }
	saveContact(e){
		e.preventDefault();
        const fd = this.state.form;
		axios.post(Constant.server_url  + '/' + `save-contact`, fd)
      .then((res) => {
		new Toaster({ type: "success", msg: res.message });
      })
      .catch((err) => {
		this.setState({
			formErrors : err.response.data
		});
        // new Toaster({ type: "error", msg: "Something Went Wrong." });
      });
	}
    render()
	{
		return (
			<>
            <Navbar transparent />
            <Breadcrumb title="Contact"/>
            <div className="container p-5">
				<div className="row">
                <div className="col-md-6">
						<h2 className="text-center">Contact Information</h2>
                        <div className='headingBorder mb-5'></div>
						<div className="row contact-info-wrap row-pb-lg ">
							<div className="col-md-12 ml-5">
								<p><span><i className="fa fa-map"></i></span> Gurugram, India </p>
							</div>
							<div className="col-md-12  ml-5">
								<p><span><i className="fa fa-envelope"></i></span> <a href="mailto:paperandthoughtsblog@gmail.com">paperandthoughtsblog@gmail.com</a></p>
							</div>
							<div className="col-md-12  ml-5">
								<p><span><i className="fa fa-globe"></i></span> <a href="#">paperandthoughts.com</a></p>
							</div>
							<div className="col-md-12 mt-5">
								<img src="assets/contact.png" className='img-responsive' />
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-12">
								<h2 className="heading-2 text-center">Get In Touch</h2>
                                <div className='headingBorder mb-5'></div>
								
								<form method="post" onSubmit={this.saveContact}>
									<div className="row form-group mb-3">
										<div className="col-md-6">
											<label htmlFor="fname">First Name</label>
											<input type="text" name="firstName" id="fname" className="form-control" placeholder="Your firstname" onChange={(event)=>{this.inputHandler(event,"firstName")}}/>
											<span className='text-danger'>{(this.state.formErrors.hasOwnProperty("firstName")) ? this.state.formErrors.firstName.msg : ''}</span>
										</div>
										<div className="col-md-6">
											<label htmlFor="lname">Last Name</label>
											<input type="text" name="lastName" id="lname" className="form-control" placeholder="Your lastname" onChange={(event)=>{this.inputHandler(event,"lastName")}}/>
											<span className='text-danger'>{(this.state.formErrors.hasOwnProperty("lastName")) ? this.state.formErrors.lastName.msg : ''}</span>
										</div>
									</div>

									<div className="row form-group  mb-3">
										<div className="col-md-12">
											<label htmlFor="email">Email</label>
											<input type="text" name="email" id="email" className="form-control" placeholder="Your email address" onChange={(event)=>{this.inputHandler(event,"email")}}/>
											<span className='text-danger'>{(this.state.formErrors.hasOwnProperty("email")) ? this.state.formErrors.email.msg : ''}</span>
										</div>
									</div>

									<div className="row form-group  mb-3">
										<div className="col-md-12">
											<label htmlFor="subject">Subject</label>
											<input type="text" name="subject" id="subject" className="form-control" placeholder="Your subject of this message"  onChange={(event)=>{this.inputHandler(event,"subject")}}/>									
											<span className='text-danger'>{(this.state.formErrors.hasOwnProperty("subject")) ? this.state.formErrors.subject.msg : ''}</span>	
										</div>
									</div>

									<div className="row form-group  mb-3">
										<div className="col-md-12">
											<label htmlFor="message">Message</label>
											<textarea name="message" id="message" cols="30" rows="10" className="form-control" placeholder="Say something about us" onChange={(event)=>{this.inputHandler(event,"message")}}></textarea>
											<span className='text-danger'>{(this.state.formErrors.hasOwnProperty("message")) ? this.state.formErrors.message.msg : ''}</span>
										</div>
									</div>
									<div className="form-group">
										<input type="submit" value="Send Message" className="btn btn-primary"/>
									</div>

								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
            <Footer />
            </>
        )
    }

}

export default Contact;