import React ,{Component } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import Card from "../../components/card";
import { Constant } from "../../helpers/Constants";
import axios from 'axios';
import Toaster from "../../helpers/Toaster";
import { Link } from "react-router-dom";

import ComingSoon from '../../components/comingsoon';

class Blog extends Component{
    constructor(props)
    {
      super(props);
      this.state = {
          blogLists: []
      }
      this.blogList = this.blogList.bind(this);
    }
    componentDidMount() {
      this.blogList();
  }
    blogList(){
		axios.get(Constant.server_url  + Constant.urlsuffix +  `/blog-list/all`)
      .then((res) => {
        this.setState({ blogLists: res.data.data });
      })
      .catch((err) => {
        new Toaster({ type: "error", msg: "Something Went Wrong." });
      });
	}
    render()
	{
		return (
			<>
            <Navbar transparent />
            <Breadcrumb title="Blogs"/>
            <div className="container p-5">
				<div className="row">
                  {
                       (this.state.blogLists.length > 0 ) ? (
                    this.state.blogLists.map((value, index) => (
                      <div className="col-md-4 mb-3" key={index}>
                        <Link to={"/blog/" + value.blog_slug} className="bloglink">
                        <Card {...value}/>
                        </Link>
                        </div>
                    )) )
                    : <ComingSoon/>
                  }
				</div>
			</div>
            <Footer />
            </>
        )
    }

}

export default Blog;